/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable consistent-return */
import React, { useEffect, useReducer, useRef, useCallback, useState } from 'react';
import produce from 'immer';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Grid, Loader, Modal, Button, Image, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import useUndo from 'use-undo';
import moment from 'moment';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { EditorState, Modifier } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Layout from '../../Components/Layout';
import {
  fetchDigestEditData,
  fetchExtractedData,
  fetchSources,
  fetchRegions,
  fetchEditorScroreText,
  fetchCategoriesData,
  fetchUsers,
  fetchDigestTags,
  saveCategory,
  modifySlug,
  fetchDigestType,
  updateDigest,
  createDigest,
  summarizeText,
  fetchVideoSummaryTags,
  fetchTranscriptData,
  fetchArticlesFormat,
  getDigestScore,
  checkPlagiarismApi,
} from '../../Api';
import OriginalPage from './OriginalPage';
import SummerisePage from './SummerisePage';
import {
  convertToLocalFormat,
  checkSummariseErrors,
  convertToServerFormat,
  convertToDraftFormat,
  defaultArticleClick,
} from './ConvertDataFormat';
import UploadImageComponent from './UploadImage';
import { trimObjectValues } from '../../Utils/trimValues';
import VideoColumn from './VideoColumn';

const validVideoUrl =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const mentionPattern = /\[@.*\]\(.*\)/;

const getUnique = (value, index, self) => self.findIndex((data) => data.id === value.id) === index;

const DEFAULT_SUMMERY_ARTICLE_DATA = {
  title: '',
  subtitle: '',
  content: '<p></p>',
  focus_card: EditorState.createEmpty(),
  images: '',
  thumbnailImage: '',
  tags: [],
  categories: [],
  sourceAction: 'Tap to Read more',
  sourceLink: '',
  type: { code: '275', title: 'long_digest' },
  format_human: 'Key Takeaways',
  slug: '',
  editorialNotes: '',
  assignedTo: null,
  digestAuthor: null,
  shortTitle: '',
  notificationShortText: '',
  labels: [],
  digestInternal: {
    content_quality: null,
    content_accuracy: null,
    content_operational: null,
    plagiarism_score: null,
    content_length: null,
  },
  originalData: {
    title: '',
    subtitle: '',
    content: '',
    focus_card: '',
    images: '',
    thumbnailImage: '',
    tags: [],
    categories: [],
    digestInternal: {
      content_quality: null,
      content_accuracy: null,
      content_operational: null,
      plagiarism_score: null,
      content_length: null,
    },
    sourceAction: 'Tap to Read more',
    sourceLink: '',
    type: { code: '275', title: 'long_digest' },
    format_human: 'Key Takeaways',
    slug: '',
    editorialNotes: '',
    assignedTo: null,
    digestAuthor: null,
    collaborators: [],
    labels: [],
    created_by: null,
    display_type: '',
    shortTitle: '',
    notificationShortText: '',
    futureUpdate: false,
    overwriteContent: false,
    quality: 0,
    relevance: 0,
    shelfLife: 'DAYS',
    readReason: '',
    articleClikable: true,
    articleFormat: null,
    regionDetail: null,
    articleClickAction: defaultArticleClick,
    articleClickActionObject: null,
    status: 'Draft',
    originalStatus: 'Draft',
    digest_poll: {},
    authors: [{ name: '' }],
    visibleFields: {
      show_share: true,
      show_bookmark: true,
      show_category: true,
      show_feedback: true,
      show_feedback_question: true,
      first_published_at: true,
      format_human: true,
      is_thumbnail_large: false
    },
    defaultPreviewCount: 1,
  },
  futureUpdate: false,
  quality: 0,
  relevance: 0,
  shelfLife: 'DAYS',
  readReason: '',
  focusCover: false,
  articleClikable: true,
  articleFormat: null,
  regionDetail: null,
  articleClickAction: defaultArticleClick,
  articleClickActionObject: null,
  status: 'Draft',
  originalStatus: 'Draft',
  digest_poll: {},
  authors: [{ name: '' }],
  isEditPage: false,
  visibleFields: {
    show_share: true,
    show_bookmark: true,
    show_category: true,
    show_feedback: true,
    show_feedback_question: true,
    first_published_at: true,
    format_human: true,
    is_thumbnail_large: false
  },
  defaultPreviewCount: 1,
};

const Content = styled.div`
  width: 100% !important;
  .grid {
    height: calc(100vh - 100px);
    .column {
      padding-top: 5px !important;
      height: 100%;
      overflow: auto;
      border: 1px solid #dcdcdc;
    }
  }
`;

const SummerisePageWrapper = styled.div`
  position: relative;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 265px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: calc(100% - 15px);
  img {
    width: 100%;
    height: 100%;
  }
`;

const CopyIconImage = styled.div`
  padding: 8px 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px #000;
  position: absolute;
  right: 10px;
  bottom: 20px;
  background-color: #fff;
  cursor: pointer;
  i {
    margin-right: 0;
  }
`;

// eslint-disable-next-line consistent-return
const convertSecondsToTimeFormat = (time) => {
  if (time !== 'undefined') {
    const date = new Date(null);
    date.setMilliseconds(time * 1000);
    return date.toISOString().substr(11, 11).replace('.', ':');
  }
};

const fetchHighestSegmentIndex = (list) => {
  const numbers = list.map((item) => item.id || item.index);
  return numbers.length > 0 ? Math.max(...numbers) : 0;
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_URL_STRING':
        draft.urlString = action.value;
        draft.summeriseArticleData.sourceLink = { ...action.value };
        draft.reload = action.reload;
        // draft.segmentList = [];
        // draft.activeSegment = {
        //   title: '',
        //   timestamp_start: convertSecondsToTimeFormat(0),
        //   timestamp_end: convertSecondsToTimeFormat(1),
        //   description: '',
        //   watch_label: '',
        // };
        // draft.activeSegmentIndex = 1;
        break;
      case 'SET_EDIT_LOADER':
        draft.loadingDigestPage = true;
        break;
      case 'SET_EDIT_ARTICLE_DATA': {
        draft.mobileData = action.editArticleData;
        const editData = JSON.parse(JSON.stringify(action.editArticleData));
        draft.summeriseArticleData = convertToLocalFormat(editData);
        draft.videoDigest = action.urlString && action.urlString.match(validVideoUrl);
        draft.summeriseArticleData.originalSlug = draft.summeriseArticleData.slug;
        if (draft.videoDigest) draft.summeriseArticleData.images = action.urlString;
        draft.urlString = action.urlString;
        draft.modifiedatDigest = editData.modified_at;
        draft.activeSegmentIndex = action.value.length + 1;
        draft.segmentList = action.value.map((segment) => ({
          ...segment,
          timestamp_start: convertSecondsToTimeFormat(segment.timestamp_start),
          timestamp_end: convertSecondsToTimeFormat(segment.timestamp_end),
        }));
        draft.plagiarised = '';
        break;
      }
      case 'SET_FETCH_PROMISES_DATA':
        draft.videoDigest = action.urlString && action.urlString.match(validVideoUrl);
        draft.FAQData = action.digestFAQData;
        draft.faqStatus = action.digestFAQsStatus;
        draft.faqId = action.faqId;
        draft.mobileData = action.editArticleData;
        draft.plagiarised = '';
        if (action.editArticleData) {
          draft.mobileData = action.editArticleData;
          const editData = JSON.parse(JSON.stringify(action.editArticleData));
          draft.summeriseArticleData = convertToLocalFormat(editData);
          if (draft.videoDigest) {
            draft.activeSegmentIndex = action.editArticleData.video_summary
              ? action.editArticleData.video_summary.summary.length + 1
              : 1;
            draft.segmentList = action.editArticleData.video_summary
              ? action.editArticleData.video_summary.summary.map((segment) => ({
                ...segment,
                timestamp_start: convertSecondsToTimeFormat(segment.timestamp_start),
                timestamp_end: convertSecondsToTimeFormat(segment.timestamp_end),
              }))
              : [];
          }
        }
        if (draft.videoDigest) {
          draft.summeriseArticleData.images = action.urlString;
          draft.originalArticleData.images = action.urlString;
        }
        if (action.originalArticleData) {
          draft.originalArticleData = action.originalArticleData;
          if (!draft.digestId) {
            draft.summeriseArticleData = {
              ...draft.summeriseArticleData,
              labels: [],
              authors:
                action.originalArticleData.authors && action.originalArticleData.authors.length > 0
                  ? action.originalArticleData.authors
                  : [{ name: '' }],
            };
          }
        }
        draft.hostsList = action.hostsList.filter(getUnique).map((host) => ({
          ...host,
          key: host.id,
          value: host.id,
          text: host.brand_name,
        }));
        draft.extraCategories = action.extraCategories.filter(getUnique).map((category) => ({
          ...category,
          id: category.id,
          key: category.id,
          value: category.id,
          text: category.title,
        }));
        draft.usersData = action.usersData
          .filter((staff) => staff.is_active)
          .map((staff) => ({
            ...staff,
            key: staff.id,
            value: staff.id,
            text: staff.name,
          }));
        draft.articleFormatTypes = action.articleFormatTypes.map((format) => ({
          ...format,
          key: format.id,
          value: format.id,
          text: format.section_name,
        }));
        draft.regionsList = action.regionsList.map((format) => ({
          ...format,
          key: format.id,
          value: format.id,
          text: format.name,
        }));
        draft.digestTypesData = action.digestTypeData.map((type) => ({
          ...type,
          key: type.code,
          value: type.code,
          text: type.title,
        }));
        draft.editorScoreText = action.editorScoreText;
        draft.loadingDigestPage = false;
        break;
      case 'SET_EXTRACT_ERROR':
        draft.extractError = action.value;
        draft.loadingDigestPage = false;
        break;
      case 'SET_EXTRA_TAGS':
        draft.extraTags = action.value.filter(getUnique).map((tag) => ({
          ...tag,
          key: tag.id,
          value: tag.id,
          text: tag.title,
        }));
        break;
      case 'SET_OPEN_UPLOAD_IMAGE':
        draft.openUploadImage = action.value;
        break;
      case 'SET_SUMMERISE_ARTICLE_DATA': {
       
        draft.summeriseArticleData = {
          ...action.value,
          digest_poll: { ...(action.value.digest_poll || {}) },
          labels: action.value.labels || [],
          originalStatus:
            draft.summeriseArticleData.originalStatus ||
            draft.summeriseArticleData.status ||
            'Draft',
        };
        draft.callAutoSaveData = !action.isPublishApiUpdate;
        break;
      }
      case 'SET_CALL_AUTO_SAVE':
        draft.callAutoSaveData = action.value;
        break;
      case 'CONFIRM_CATEGORY_ADD':
        draft.confirmCategoryAdd = action.value;
        break;
      case 'ADD_CATEGORY':
        draft.addCategory = !draft.addCategory;
        break;
      case 'SET_IMAGE':
        draft.categoryImage = action.value;
        break;
      case 'PLAGIARISED_CONTENT':
        draft.plagiarised = action.value;
        draft.plagiarisedTitle = action.newTitle;
        draft.loadingDigestPage = false;
        break;
      case 'UPLOAD_IMAGE_STATUS':
        if (action.addNewUpload) {
          draft.addNewUpload = action.addNewUpload;
        }
        draft.uploadImage = action.status;
        break;
      case 'SET_ORIGINAL_ARTICLE_DATA_CHANGES':
        draft.originalArticleData = action.value;
        break;
      case 'SLUG_EDITABLE':
        draft.editSlug = !draft.editSlug;
        break;
      case 'SET_PUBLISH_API_REQUEST': {
        draft.loadingPublish = !draft.loadingPublish;
        draft.publishErrorMessage = null;
        if (action.modifiedAt) {
          draft.modifiedatDigest = action.modifiedAt;
        }
        break;
      }
      case 'SET_PUBLISH_API_FAILURE':
        draft.publishErrorMessage = action.msg || 'Something went wrong!';
        draft.loadingPublish = false;
        break;
      case 'SET_DIGEST_TYPES':
        draft.digestTypesData = action.value.map((type) => {
          const data = {
            ...type,
            key: type.code,
            value: type.code,
            text: type.title,
          };
          return data;
        });
        break;
      case 'SET_FORCE':
        draft.update = action.value;
        break;
      case 'DISABLE_DIGEST_LOADING':
        draft.loadingDigestPage = false;
        break;
      case 'SET_ARTICLES_FORMAT': {
        draft.articleFormatTypes = action.value.map((format) => {
          const data = {
            ...format,
            key: format.id,
            value: format.id,
            text: format.section_name,
          };
          return data;
        });
        break;
      }
      case 'SET_SUMMERISE_ARTICLE_ERRORS':
        draft.summeriseArticleErrors = action.value;
        break;
      case 'SET_EXTRA_CATEGORIES':
        draft.extraCategories = action.value.filter(getUnique).map((category) => ({
          ...category,
          id: category.id,
          key: category.id,
          value: category.id,
          text: category.title,
        }));
        break;
      case 'SET_SEGMENT_LIST':
        draft.segmentList = action.value;
        draft.activeSegment = {
          title: '',
          timestamp_start: convertSecondsToTimeFormat(0),
          timestamp_end: convertSecondsToTimeFormat(1),
          description: '',
          watch_label: '',
        };
        draft.autoSummaryPopup = false;
        draft.activeSegmentIndex = fetchHighestSegmentIndex(draft.segmentList) + 1;
        break;
      case 'SET_ACTIVE_SEGMENT':
        draft.activeSegment = action.value;
        draft.activeSegmentIndex = action.value.segment_id || action.value.index || 1;
        break;
      case 'SET_VIDEO_SUMMARY_TAGS':
        draft.videoSummary = action.value.map((summary) => ({
          key: summary.id,
          value: summary.id,
          text: summary.watch_label,
        }));
        break;
      case 'VIDEO_DURATION':
        draft.formattedTime = action.formattedTime;
        break;
      case 'SET_PREVIEW':
        draft.previewMode = !draft.previewMode;
        draft.subtitleMode = false;
        break;
      case 'SET_SUBTITLE':
        draft.subtitleMode = !draft.subtitleMode;
        draft.previewMode = false;
        break;
      case 'SET_TRANSCRIPT_DATA':
        draft.videoTranscriptData = action.transcriptData
          ? action.transcriptData.map((item, index) => {
            item.index = index;
            return item;
          })
          : [];
        draft.autoSummary = (action.autoSummary && action.autoSummary.summary) || [];
        draft.transcriptError = null;
        break;
      case 'SET_ACTIVE_TRANSCRIPT':
        draft.activeTranscript = JSON.parse(JSON.stringify(action.value));
        if (action.manuallyChange) {
          draft.activeTranscript.manuallyChange = true;
        }
        break;
      case 'SET_DATA':
        draft.activeSegment[action.fieldType] = action.value;
        break;
      case 'SET_SEGMENT_ERRORS':
        draft.segmentError = action.value;
        break;
      case 'SET_SCORE_LIST':
        draft.scoreList = action.value;
        draft.scoreRelevance = action.scoreRelevance;
        break;
      case 'SET_SEGMENTS_PREVIEW':
        draft.segmentList = draft.segmentList.map((item) => {
          if (item.id === action.value.id) {
            return {
              ...item,
              is_for_preview: !item.is_for_preview,
            };
          }

          return item;
        });
        break;
      case 'SET_TRANSCRIPT_ERROR':
        draft.transcriptError = action.value;
        draft.summeriseArticleErrors = {
          ...draft.summeriseArticleErrors,
          images: action.value,
          transcriptError: action.value,
        };
        break;

      case 'SET_EDITOR_STATE_HTML_CONTENT':
        draft.summeriseArticleData.content = action.value;
        break;
    }
  });

export const convertTimeToSeconds = (time) => {
  const a = time.split(':'); // split it at the colons
  if (a.length === 2) {
    a.unshift('00');
  } else if (a.length === 3) {
    a.unshift('00');
    a.unshift('00');
  }
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
};

const calculateHeightWidth = (wholeValue, parts) => {
  return Number(((Number(parts) * 100) / Number(wholeValue)).toFixed(2));
};

const percentageToPx = (wholeValue, parts) => {
  const percentage = Number(((Number(wholeValue) / 100) * Number(parts)).toFixed(2));
  return percentage;
};

const initialState = {
  urlString: null,
  loadingDigestPage: false,
  summeriseArticleData: DEFAULT_SUMMERY_ARTICLE_DATA,
  originalArticleData: { tags: [], categories: [] },
  hostsList: [],
  extraCategories: [],
  regionsList: [],
  articleFormatTypes: [],
  digestTypesData: [],
  extractError: null,
  summeriseArticleErrors: {},
  extraTags: [],
  modifiedatDigest: new Date(),
  openUploadImage: null,
  confirmCategoryAdd: '',
  loadingPublish: false,
  plagiarised: '',
  plagiarisedTitle: '',
  publishErrorMessage: null,
  activeSegment: {
    title: '',
    timestamp_start: convertSecondsToTimeFormat(0),
    timestamp_end: convertSecondsToTimeFormat(1),
    description: '',
    watch_label: '',
  },
  activeSegmentIndex: 1,
  segmentList: [],
  videoSummary: [],
  videoTranscriptData: [],
  activeTranscript: null,
  active: { tag: [], categories: [] },
  segmentError: {},
  editorScoreText: [],
  FAQData : [],
  faqId: null,
  faqStatus: false
};

const DEFAULT_CROP_COORD = {
  topLeft: {
    x_coord_: 0,
    y_coord: 0,
  },
  bottomRight: {
    x_coord_: 0,
    y_coord: 0,
  },
};

const Dashboard = ({ location, match: { params } }) => {
  const history = useHistory();
  const videoRef = useRef(null);
  const activeSegmentRef = useRef(null);
  let selectedTag = {};
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    digestId: params.id || null,
    summeriseArticleData: {
      ...initialState.summeriseArticleData,
      content: queryString.parse(location.search).content
        ? convertToDraftFormat(queryString.parse(location.search).content)
        : '<p></p>',
      sourceLink: queryString.parse(location.search).url
        ? queryString.parse(location.search).url
        : '',
    },
  });

  const autoSaveTimeoutRef = useRef(null);

  const {
    urlString,
    digestId,
    summeriseArticleData,
    originalArticleData,
    summeriseArticleErrors,
    extractError,
    loadingDigestPage,
    confirmCategoryAdd,
    addCategory,
    categoryImage,
    uploadImage,
    extraTags,
    extraCategories,
    callAutoSaveData,
    segmentList,
    activeSegment,
    activeSegmentIndex,
    videoSummary,
    formattedTime,
    previewMode,
    videoTranscriptData,
    activeTranscript,
    subtitleMode,
    loadingPublish,
    segmentError,
    scoreList,
    plagiarised,
    plagiarisedTitle,
    transcriptError,
    scoreRelevance,
    FAQData,
    faqStatus,
    faqId
  } = state;
  const editorRef = useRef(null);
  const [isMobile, setMobile] = useState(false);
  const [showFullLeft, setShowFullLeft] = useState(true);
  const [aspectRatio, setAspectRatio] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [mobilePreviewData, setMobilePreviewData] = useState(false);
  const [cropCoord, setCropCoord] = useState(DEFAULT_CROP_COORD);
  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  const [coOrdinate, setCoOrdinate] = useState({});
  const [isMarkAsStart, setIsMarkAsStart] = useState(true);

  //Function for remove P tags from list.
  function removePTagsWithinList(data) {
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = data;
    var ulElements = tempDiv.querySelectorAll('ul');
    ulElements.forEach(function (ulElement) {
      var pTagsWithinList = ulElement.querySelectorAll('li p');
      pTagsWithinList.forEach(function (pTag) {
        var textContent = pTag.textContent;
        var textNode = document.createTextNode(textContent);
        pTag.parentNode.replaceChild(textNode, pTag);
      });
    });
    return tempDiv.innerHTML;
  }

  const handleEditorChange = (content) => {
    const parsedData = removePTagsWithinList(content)
    dispatch({ type: 'SET_EDITOR_STATE_HTML_CONTENT', value: parsedData });
  };

  useEffect(() => {
    fetchDigestData(digestId);
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [handleResize]);
  const editSummeriseData = JSON.parse(JSON.stringify(summeriseArticleData));
  editSummeriseData.content = summeriseArticleData.content;
  editSummeriseData.focus_card = summeriseArticleData.focus_card;

  const [
    // summariseFormState,
    {
      set: setSummariseFormState,
      // reset: resetSummariseFormState,
      undo: undoSummariseFormState,
      redo: redoSummariseFormState,
      canUndo,
      canRedo,
    },
  ] = useUndo(summeriseArticleData);

  useEffect(() => {
    if (activeSegment) {
      activeSegmentRef.current = activeSegment;
    }
  }, [activeSegment]);

  useEffect(() => {
    fetchVideoSummaryTags().then((response) => {
      dispatch({ type: 'SET_VIDEO_SUMMARY_TAGS', value: response.data.results });
    });
  }, []);

  const fetchDigestScores = async (id) => {
    getDigestScore(id)
      .then((response) => {
        dispatch({
          type: 'SET_SCORE_LIST',
          value: response.data.editor_score_quality.map((score) => ({
            key: score.score,
            value: score.score,
            text: score.title,
          })),
          scoreRelevance: response.data.editor_score_relevance.map((score) => ({
            key: score.score,
            value: score.score,
            text: score.title,
          })),
        });
      })
      .catch((error) => {
        ToastsStore.error('Unable to fetch digest list  222222');
      });
  };

  useEffect(() => {
    if (location.pathname === '/digest-create') fetchDigestScores(1);
  }, [location.pathname]);

  const fetchTranscriptSummaryData = (url) => {
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(validUrl)) {
      const videoId = url.match(validUrl)[1];
      fetchTranscriptData({ youtubeId: videoId })
        .then((response) => {
          dispatch({
            type: 'SET_TRANSCRIPT_DATA',
            transcriptData: response.data.transcript,
            autoSummary: response.data.auto_summary,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400 && !digestId) {
            dispatch({
              type: 'SET_TRANSCRIPT_ERROR',
              value: error.response.data && error.response.data.message,
            });
          }
        });
      dispatch({ type: 'LOADING', value: false });
    }
  };

  useEffect(() => {
    if (location.state && location.state.urlString && !urlString) {
      dispatch({ type: 'SET_URL_STRING', value: location.state.urlString });
      fetchTranscriptSummaryData(location.state.urlString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, urlString]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyDown = (e) => {
    if (e.metaKey && e.which === 90 && !e.shiftKey && canUndo) {
      undoSummariseFormState();
    } else if (e.metaKey && e.which === 90 && e.shiftKey && canRedo) {
      redoSummariseFormState();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const fetchUrlData = ({ url, fetchDigest }) => {
    // dispatch({ type: 'SET_EDIT_LOADER' });
    if (digestId && !fetchDigest) dispatch({ type: 'SET_EDIT_LOADER' });
    videoRef.current = null;
    // let promisesArray;
    const urlState = location.state || url ? location.state.urlString || url : '';
    const vidDigest = urlState && urlState.match(validVideoUrl);
    // if (digestId || !vidDigest)
    let isVideoDigest = false;
    if (
      (digestId &&
        !queryString.parse(location.search).url &&
        !(location.state && location.state.urlString)) ||
      vidDigest
    ) {
      isVideoDigest = true;
    }
    const promisesArray = [
      fetchExtractedData({ url }).catch((error) => {
        if (error.response && error.response.status === 400) {
          if (!vidDigest)
            dispatch({
              type: 'SET_SUMMERISE_ARTICLE_ERRORS',
              value: {
                ...summeriseArticleErrors,
                url: (error.response.data && error.response.data[0]) || 'Invalid URL',
              },
            });
        } else if (error.response && error.response.status === 500) {
          dispatch({ type: 'SET_EXTRACT_ERROR', value: "Website can't be loaded currently" });
        } else {
          dispatch({
            type: 'SET_EXTRACT_ERROR',
            value:
              (error.response && error.response.data[0]) || "Website can't be loaded currently",
          });
        }
        if (window) window.Raven.captureException(error.response);
      }),
      fetchSources(),
      fetchCategoriesData(),
      fetchUsers(1, 50),
      fetchArticlesFormat(),
      fetchDigestType(),
      fetchRegions(),
      fetchEditorScroreText({ videoDigest: !!isVideoDigest }).catch((error) => {
        console.log('🚀 ~ file: index.js ~ line 771 ~ fetchUrlData ~ error', error);
      }),
    ];

    if (digestId && !fetchDigest) {
      promisesArray.push(fetchDigestEditData({ id: digestId }));
    }

    Promise.all(promisesArray)
      .then(
        ([
          extractedData,
          hostsData,
          categoriesData,
          usersData,
          articlesFormatData,
          digestTypeData,
          regionsList,
          editorScoreText,
          editDigestData,
        ]) => {
          //code to format faq data
          const toArray = editDigestData?.data?.faq_generator?.question_answer ?  Object.entries(editDigestData?.data?.faq_generator?.question_answer).map(([key, value]) => ({ ...value, is_regenerated: editDigestData?.data?.faq_generator?.is_answer_generate ? value?.is_regenerated : true })) ?? []:[];          
          dispatch({
            type: 'SET_FETCH_PROMISES_DATA',
            originalArticleData: url && extractedData && extractedData.data,
            hostsList: hostsData.data.results || [],
            extraCategories: categoriesData.data.results || [],
            usersData: usersData.data.results || [],
            articleFormatTypes: articlesFormatData.data.results || [],
            regionsList: regionsList.data.region || [],
            editArticleData: editDigestData && editDigestData.data,

            digestFAQData : toArray,
            faqId: editDigestData?.data?.faq_generator?.id,
            digestFAQsStatus: editDigestData?.data?.faq_generator?.display,

            digestTypeData:
              digestTypeData && digestTypeData.data && digestTypeData.data.results
                ? digestTypeData.data.results
                : [],
            urlString: location.state ? location.state.urlString : '',
            editorScoreText: (editorScoreText && editorScoreText.data.results) || [],
          });
          fetchDigestScores(
            editDigestData && editDigestData.data.categories.length
              ? editDigestData.data.categories[0].id
              : 1,
          );
          if (extractedData) {
            fetchDigestTags({ content: extractedData.data.content }).then((tagsRes) => {
              dispatch({ type: 'SET_EXTRA_TAGS', value: tagsRes.data.results });
            });
          }
        },
      )
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDigestData = (id) => {
    dispatch({ type: 'SET_EDIT_LOADER' });
    fetchDigestEditData({ id })
      .then((digestData) => {
        dispatch({
          type: 'SET_EDIT_ARTICLE_DATA',
          editArticleData: digestData.data,
          urlString: digestData.data.source.link,
          value: digestData.data.video_summary ? digestData.data.video_summary.summary : [],
        });
        setMobilePreviewData(digestData.data);
        fetchUrlData({ url: digestData.data.source.link, fetchDigest: true });
      })
      .catch((error) => {
        console.log('🚀 ~ file: index.js ~ line 52 ~ error', error);
      });
  };

  useEffect(() => {
    if (
      (location.state &&
        location.state.urlString &&
        (!urlString || location.state.urlString !== urlString)) ||
      (queryString.parse(location.search).url && !urlString)
    ) {
      const url =
        location.state && location.state.urlString
          ? location.state.urlString
          : queryString.parse(location.search).url;
      dispatch({ type: 'SET_URL_STRING', value: url });
      fetchUrlData({ url });
    } else {
      const url =
        location.state && location.state.urlString
          ? location.state.urlString
          : queryString.parse(location.search).url;
      if (!url && !urlString) {
        fetchUrlData({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, urlString]);

  useEffect(() => {
    if (
      digestId &&
      !queryString.parse(location.search).url &&
      !(location.state && location.state.urlString)
    ) {
      fetchDigestData(digestId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(originalArticleData));
    let changes = false;
    if (extraTags && extraTags.length > 0) {
      const tags = originalArticleData.tags.concat(extraTags).filter(getUnique);
      if (tags.length !== originalArticleData.tags.length) {
        data.tags = tags;
        changes = true;
      }
    }
    if (extraCategories && extraCategories.length > 2) {
      let categories = originalArticleData.categories || [];
      categories = categories.concat(extraCategories).filter(getUnique);
      if (
        !originalArticleData.categories ||
        categories.length !== originalArticleData.categories.length
      ) {
        data.categories = categories;
        changes = true;
      }
    }

    if (changes) {
      dispatch({ type: 'SET_ORIGINAL_ARTICLE_DATA_CHANGES', value: data });
    }
  }, [originalArticleData, extraTags, extraCategories, extractError]);

  const handleCopyText = ({ type, text, start }) => {
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    // dispatch({ type: 'SET_OPEN_UPLOAD_IMAGE', value: false });
    if (type === 'title') {
      const contentText = text;
      selectedData.title = contentText.split(' ').slice(0, 30).join(' ');
      selectedData.titleCursor = start;
    } else if (type === 'subtitle') {
      const contentText = text;
      selectedData.subtitle = contentText.split(' ').slice(0, 12).join(' ');
    } else if (type === 'content-start') {
      const selection = selectedData.content.getSelection();
      const contentState = selectedData.content.getCurrentContent();
      const ncs = Modifier.insertText(contentState, selection, text);
      selectedData.content = EditorState.push(selectedData.content, ncs, 'insert-fragment');

      // let contentText = '';
      // if (selectedData.markup.apply) contentText = `${htmlToFormattedText(text.replace(/>[\s]+</g, '><'))} ${selectedData.content}`;
      // else contentText = `${text} ${selectedData.content}`;
      // if (selectedData.type.code === '200' || selectedData.type.code === '275' || selectedData.type.code === '300')
      // selectedData.content = contentText;
      // else selectedData.content =  htmlToFormattedText(contentText.replace(/(?:<br>\s+){2,}/ig,'<br>').replace(/(<p><br>)/ig, '<p>')).replace(/(<([^>]+)>)/ig, '').split(' ").slice(0, 100).join(" ")
    } else if (type === 'content-end') {
      // let contentText = '';
      // if (selectedData.markup.apply) contentText = `${selectedData.content.trim()}${selectedData.content ? ' ' : ''} ${htmlToFormattedText(text.replace(/>[\s]+</g, '><'))}`;
      // else contentText = `${selectedData.content.trim()}${selectedData.content ? ' ' : ''}${text}`;
      // if (selectedData.type.code === '200' || selectedData.type.code === '275' || selectedData.type.code === '300')
      // selectedData.content = contentText.replace(/(?:<br>\s+){2,}/ig, "<br>").replace(/>[\s]+</g, '><');
      // else selectedData.content =  htmlToFormattedText(contentText.replace(/(?:<br>\s+){2,}/ig,"<br>").replace(/(<p><br>)/ig, '<p>')).replace(/(<([^>]+)>)/ig, '').split(" ").slice(0, 100).join(" ")
    } else if (type === 'image') {
      selectedData.images = text;
      dispatch({ type: 'SET_OPEN_UPLOAD_IMAGE', value: text });
    } else if (type === 'thumbnailImage') {
      selectedData.thumbnailImage = text;
      dispatch({ type: 'SET_OPEN_UPLOAD_IMAGE', value: text });
    }
    // if (type === 'content-end' || type === 'content-start') {}
    // selectedData = checkType(selectedData);
    // handleAutoSave();
    // setSummariseFormState(selectedData);
    dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
    // dispatch({ type: 'SET_FORCE', value: true });
  };

  const handleChangeSummary = ({ type, text, name, index }) => {
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    if (type !== 'version_content') selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    if (type === 'images') {
      dispatch({ type: 'SET_OPEN_UPLOAD_IMAGE', value: false });
      selectedData.images = text;
    } else if (type === 'thumbnailImage') {
      dispatch({ type: 'SET_OPEN_UPLOAD_IMAGE', value: false });
      selectedData.thumbnailImage = text;
    } else if (type === 'authors') {
      selectedData.authors = text;
    } else if (type === 'addAuthors') {
      const authorsData = selectedData.authors;
      authorsData.push({ name: '' });
    } else if (type === 'removeAuthors') {
      const authorsData = selectedData.authors;
      authorsData.splice(text.index, 1);
    } else if (type === 'digest_poll') {
      if (name === 'question') {
        const pollQues = selectedData.digest_poll ? { ...selectedData.digest_poll.question } : {};
        selectedData.digest_poll = {
          ...selectedData.digest_poll,
          question: {
            ...pollQues,
            question: text,
          },
        };
      } else if (name === 'choice') {
        const pollChoices = selectedData.digest_poll.choices || [];
        const choiceArray = pollChoices;
        if (!choiceArray[index]) {
          while (pollChoices.length < index) pollChoices.push({});
        }
        const choice = { ...pollChoices[index] };
        pollChoices[index] = { ...choice, choice: text };
        selectedData.digest_poll = {
          ...selectedData.digest_poll,
          choices: pollChoices,
        };
      }else if (name === 'has_poll') {
        selectedData.digest_poll = {
          ...selectedData.digest_poll,
          has_poll: text || false,
        };
      }
    } else if (type === 'pollRemove') {
      if (Object.keys(selectedData.digest_poll).length) selectedData.digest_poll = {};
      else selectedData.digest_poll = { choices: [{}, {}] };
    } else if (type === 'focus_card') {
      selectedData.focus_card = text;
    } else if (type === 'articleClickAction') {
      selectedData.articleClickAction = text;
      selectedData.articleClickActionObject = null;
    } else if (type === 'articleClickActionObject') {
      selectedData.articleClickActionObject = text;
    } else if (type === 'editorScoreInternal') {
      selectedData.digestInternal[name] = text;
    }
    // else if (type === 'contentAccuracy') {
    //   selectedData.digestInternal.content_accuracy = text;
    // } else if (type === 'contentOperational') {
    //   selectedData.digestInternal.content_operational = text;
    // } else if (type === 'plagiarismScore') {
    //   selectedData.digestInternal.plagiarism_score = text;
    // } else if (type === 'contentLength') {
    //   selectedData.digestInternal.content_length = text;
    // }
    else if (type === 'version_content') {
      // selectedData.content = convertToDraftFormat(text);
      selectedData.content = text;
      if (text.match(mentionPattern)) {
        selectedData.type = { code: '300', title: 'collection digest' };
      }
      // selectedData.content = text;
    } else if (type === 'defaultPreviewCount') {
      selectedData.defaultPreviewCount = text;
    } else {
      selectedData[type] = text;
    }
    if (type === 'content') {
      const contentText = JSON.stringify(stateToHTML(text.getCurrentContent()));
      if (contentText.match(mentionPattern)) {
        selectedData.type = { code: '300', title: 'collection digest' };
      }
    }
    // setSummariseFormState(selectedData);
    dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
    dispatch({ type: 'SET_FORCE', value: true });
    if (Object.keys(summeriseArticleErrors).length > 1) {
      const errors = checkSummariseErrors({
        data: selectedData,
        videoDigest: urlString && urlString.match(validVideoUrl),
        transcriptError,
      });
      dispatch({ type: 'SET_SUMMERISE_ARTICLE_ERRORS', value: errors });
    }
    // }
  };

  const addChoices = () => {
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    selectedData.digest_poll.choices.push({ choice: '' });
    // handleAutoSave();
    // setSummariseFormState(selectedData);
    dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
    // dispatch({ type: 'SET_FORCE', value: true });
  };

  const removeChoice = (index) => {
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    selectedData.digest_poll.choices.splice(index, 1);
    // handleAutoSave();
    // setSummariseFormState(selectedData);
    dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
    // dispatch({ type: 'SET_FORCE', value: true });
  };

  const fetchSuggestions = (value, chips) => {
    const chipsTemp = !chips || !chips[0] || typeof chips === 'function' ? {} : chips[0];
    return new Promise((resolve, reject) => {
      if (value.length >= 1 && !Object.keys(chipsTemp).length) {
        const originalCategories = originalArticleData.categories || [];
        const filtered = originalCategories
          .filter((opt) => selectedTag.id !== opt.id)
          .filter(
            (opt) =>
              editSummeriseData.categories.findIndex((category) => category.id === opt.id) === -1,
          )
          .filter((opt) => opt.title.toLowerCase().includes(value.toLowerCase()));
        resolve(filtered);
      } else if (!value && !Object.keys(chipsTemp).length) {
        const selectedTagTemp = selectedTag || {};
        const originalCategories = originalArticleData.categories || [];
        const filterdData = originalCategories
          .filter((opt) => selectedTagTemp.id !== opt.id)
          .filter(
            (opt) => editSummeriseData.categories.findIndex((tag) => tag.id === opt.id) === -1,
          );
        resolve(filterdData);
      } else if (!value && Object.keys(chipsTemp).length) {
        const originalCategories = originalArticleData.categories || [];
        const filtered = originalCategories
          .filter((opt) => opt.id !== chipsTemp.id)
          .filter(
            (opt) => editSummeriseData.categories.findIndex((tag) => tag.id === opt.id) === -1,
          );
        resolve(filtered);
      } else {
        resolve([]);
      }
    });
  };

  const fetchTagSuggestions = async (value, chips) => {
    const chipsTemp = !chips || !chips[0] || typeof chips === 'function' ? {} : chips[0];
    const tagsRes = await fetchDigestTags({ content: editSummeriseData.content });
    const { results } = tagsRes.data;
    const originalTags = originalArticleData.tags || [];
    const tags = originalTags.concat(results).filter(getUnique);
    if (value.length >= 1 && !Object.keys(chipsTemp).length) {
      const originalTagsTemp = tags || [];
      const filtered = originalTagsTemp
        .filter((opt) => selectedTag.id !== opt.id)
        .filter(
          (opt) => editSummeriseData.tags.findIndex((category) => category.id === opt.id) === -1,
        )
        .filter((opt) => opt.title.toLowerCase().includes(value.toLowerCase()));
      return filtered;
    }
    if (!value.length && !Object.keys(chipsTemp).length) {
      const originalTagsTemp = tags || [];
      const filterdData = originalTagsTemp
        .filter((opt) => selectedTag.id !== opt.id)
        .filter((opt) => editSummeriseData.tags.findIndex((tag) => tag.id === opt.id) === -1);
      return filterdData.slice(0, 10);
    }
    if (!value.length && Object.keys(chipsTemp).length) {
      const originalTagsTemp = tags || [];
      const filtered = originalTagsTemp
        .filter((opt) => opt.id !== chipsTemp.id)
        .filter((opt) => editSummeriseData.tags.findIndex((tag) => tag.id === opt.id) === -1);
      return filtered;
    }
    return originalArticleData.tags;
  };

  const setEditSlug = () => {
    dispatch({ type: 'SLUG_EDITABLE' });
  };

  const handleAddTags = (chips) => {
    selectedTag = { ...chips[chips.length - 1] };
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    selectedData.tags = chips || [];
    // handleAutoSave();
    // setSummariseFormState(selectedData);
    dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
    fetchTagSuggestions('', chips);
  };

  const handleAddTagsGroups = (chips) => {
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    selectedData.tags = chips || [];
    // handleAutoSave();
    // setSummariseFormState(selectedData);
    dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
  };

  const onChangeCategoriesOption = (chips, removed) => {
    selectedTag = chips[chips.length - 1];
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    if (
      !removed &&
      !originalArticleData.categories.filter(
        (category) => category.id === chips[chips.length - 1].id,
      ).length
    ) {
      dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: selectedTag });
    } else {
      selectedData.categories = chips || [];
      // handleAutoSave();
      dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
      // setSummariseFormState(selectedData);
      fetchSuggestions('', chips);
      if (
        (!chips.length && editSummeriseData.categories.length) ||
        (editSummeriseData.categories.length &&
          chips.length &&
          chips[0].id !== editSummeriseData.categories[0].id) ||
        !editSummeriseData.categories.length
      )
        fetchDigestScores(chips.length ? chips[0].id : 1);
    }
  };

  //-------------------------------------

  //----------------------------------------

  const validateSlug = (slug) => {
    const pattern = /^[a-z-0-9]+$/;
    const errors = {};
    if (!slug.match(pattern)) {
      errors.slug = 'Slug should contain lowercase letters, numbers, and hyphens only';
      dispatch({ type: 'SET_SUMMERISE_ARTICLE_ERRORS', value: errors });
    } else {
      const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
      selectedData.content = summeriseArticleData.content;
      selectedData.focus_card = summeriseArticleData.focus_card;
      modifySlug({ id: selectedData.id, slug })
        .then(() => {
          ToastsStore.success('Slug updated successfully.');
          setEditSlug();
          selectedData.slug = slug;
          selectedData.originalSlug = slug;
          dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
          // handleAutoSave();
          // setSummariseFormState(selectedData);
        })
        .catch((error) => {
          dispatch({
            type: 'SET_SUMMERISE_ARTICLE_ERRORS',
            value: { slug: error.response.data.detail },
          });
        });
    }
  };

  const publishDigestApi = (continueEditing, data, isAutoSave) => {
    if (data.id) {
      updateDigest({ data, id: data.id })
        .then((response) => {
          if (!isAutoSave) {
            ToastsStore.success('Digest updated successfully.');
          }
          if (!continueEditing) history.push('/');
          else {
            const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
            selectedData.content = summeriseArticleData.content;
            selectedData.focus_card = summeriseArticleData.focus_card;
            selectedData.originalStatus = data.status;
            // setSummariseFormState(selectedData);
            dispatch({
              type: 'SET_SUMMERISE_ARTICLE_DATA',
              value: selectedData,
              isPublishApiUpdate: true,
            });
            dispatch({ type: 'SET_PUBLISH_API_REQUEST', modifiedAt: response.data.modified_at });
          }
        })
        .catch((error) => {
          dispatch({
            type: 'SET_PUBLISH_API_FAILURE',
            msg: (error.response && error.response.data && error.response.data[0]) || null,
          });
        });
    } else {
      createDigest({ data })
        .then((response) => {
          if (!continueEditing) history.push('/');
          else history.push(`/digests/${response.data.id}`);
          ToastsStore.success('Digest created successfully.');
        })
        .catch((error) => {
          dispatch({
            type: 'SET_PUBLISH_API_FAILURE',
            msg: (error.response && error.response.data && error.response.data[0]) || null,
          });
        });
    }
  };

  const handlePublish = (continueEditing, status, isAutoSave) => {
    if (!isAutoSave) {
      clearTimeout(autoSaveTimeoutRef.current);
    }
    const data = trimObjectValues(JSON.parse(JSON.stringify(summeriseArticleData)), {
      removeBlanks: true,
    });
    data.content = summeriseArticleData.content;
    data.focus_card = summeriseArticleData.focus_card;
    let errors = {};
    let dataVideo;
    if (video) {
      dataVideo = JSON.parse(JSON.stringify(segmentList));
    }
    errors = checkSummariseErrors({
      data,
      segment: segmentList[0] || {},
      videoDigest: urlString && urlString.match(validVideoUrl),
      // transcriptError,
    });

    if (Object.keys(errors).length === 0) {
      const publishDataFormat = convertToServerFormat(
        data,
        urlString,
        validVideoUrl,
        dataVideo,
        videoSummary,
      );
      dispatch({ type: 'SET_PUBLISH_API_REQUEST' });
      publishDigestApi(continueEditing, publishDataFormat, isAutoSave);
    } else {
      dispatch({ type: 'SET_SUMMERISE_ARTICLE_ERRORS', value: errors });
    }
  };

  const autoSummarize = () => {
    summarizeText(urlString)
      .then((e) => {
        const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
        selectedData.content = summeriseArticleData.content;
        selectedData.focus_card = summeriseArticleData.focus_card;
        if (e.data.summary) {
          selectedData.content = convertToDraftFormat(e.data.summary);
          // handleAutoSave();
          // setSummariseFormState(selectedData);
          dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
          dispatch({ type: 'SET_FORCE', value: true });
        } else {
          dispatch({
            type: 'SET_SUMMERISE_ARTICLE_ERRORS',
            value: { ...summeriseArticleErrors, content: 'Sorry could not be summarized' },
          });
          setTimeout(
            () =>
              dispatch({
                type: 'SET_SUMMERISE_ARTICLE_ERRORS',
                value: { ...summeriseArticleErrors, content: '' },
              }),
            5000,
          );
        }
      })
      .catch((e) => {
        if (window) window.Raven.captureException(e.response);
        dispatch({
          type: 'SET_SUMMERISE_ARTICLE_ERRORS',
          value: { ...summeriseArticleErrors, content: 'Sorry could not be summarized' },
        });
        setTimeout(
          () =>
            dispatch({
              type: 'SET_SUMMERISE_ARTICLE_ERRORS',
              value: { ...summeriseArticleErrors, content: '' },
            }),
          5000,
        );
      });
  };

  const setContent = (response, content, fieldName) => {
    let newContent = '';
    let pos = 0;
    let last = '<span>';
    const insertionPoints = [];
    const field = fieldName === 'body' ? 'matches_content' : 'matches_title';
    // const matchArray = response.data.matches_content || response.data.parent_matches;
    // matchArray.forEach((cont) => {
    if (response.data.parent_matches) {
      const matchArray = response.data.parent_matches;
      matchArray.forEach((cont) => {
        if (cont[field].length) {
          cont[field].forEach((char) => {
            insertionPoints.push({
              location: char.child_postion,
              val: '<span class="highlight">',
            });
            insertionPoints.push({
              location: char.child_postion + char.size,
              val: '</span>',
            });
          });
        }
      });
    } else if (response.data[field]) {
      const matchArray = response.data[field];
      if (matchArray.length) {
        // const field = fieldName === 'body' ? 'matches_content' : 'matches_title';
        matchArray.forEach((char) => {
          insertionPoints.push({
            location: char.child_postion,
            val: '<span class="highlight">',
          });
          insertionPoints.push({
            location: char.child_postion + char.size,
            val: '</span>',
          });
        });
      }
    }
    // });
    insertionPoints.sort((a, b) => a.location - b.location);
    if (insertionPoints.length) {
      const result = [insertionPoints[0]];
      for (pos = 1; pos < insertionPoints.length; pos += 1) {
        const char = insertionPoints[pos].val;
        if (char === last) {
          if (char === '</span>') result[result.length - 1] = insertionPoints[pos];
        } else {
          result.push(insertionPoints[pos]);
          last = char;
        }
      }
      let index = 0;
      result.forEach((point) => {
        if (point.location !== index) {
          newContent = newContent.concat(content.slice(index, point.location));
        }
        newContent = newContent.concat(point.val);
        index = point.location;
      });
      newContent = newContent.concat(content.slice(index, content.length));
      return newContent;
    }
    // ToastsStore.success("Your content isn't plagiarised");
    // dispatch({ type: 'PLAGIARISED_CONTENT', value: '' });
  };

  const checkPlagiarism = (original, title) => {
    dispatch({ type: 'SET_EDIT_LOADER' });
    let data = { digestId: parseInt(digestId, 10) };
    if (original)
      data = {
        digestId: parseInt(digestId, 10),
        matchContent: stateToHTML(original.getCurrentContent()),
        matchTitle: title,
      };
    checkPlagiarismApi(data)
      .then((response) => {
        const matchArray = response.data.parent_matches || response.data.matches_content;
        if (!matchArray.length) {
          ToastsStore.success("Your content isn't plagiarised");
          dispatch({ type: 'PLAGIARISED_CONTENT', value: '', newTitle: '' });
        } else {
          const content = response.data.child_content;
          const newContent = setContent(response, content, 'body');
          const newTitle = setContent(response, summeriseArticleData.title, 'title');
          if (!newContent && !newTitle) {
            ToastsStore.success("Your content isn't plagiarised");
            dispatch({ type: 'PLAGIARISED_CONTENT', value: '', newTitle: '' });
          }
          dispatch({
            type: 'PLAGIARISED_CONTENT',
            value: newContent || '',
            newTitle: newTitle || '',
          });
        }
      })
      .catch((e) => {
        dispatch({ type: 'DISABLE_DIGEST_LOADING' });
        if (window) window.Raven.captureException(e.response);
        dispatch({
          type: 'SET_SUMMERISE_ARTICLE_ERRORS',
          value: { ...summeriseArticleErrors, content: 'Sorry could not check for plagiarism' },
        });
        setTimeout(
          () =>
            dispatch({
              type: 'SET_SUMMERISE_ARTICLE_ERRORS',
              value: { ...summeriseArticleErrors, content: '' },
            }),
          5000,
        );
      });
  };

  const handleAutoSave = () => {
    clearTimeout(autoSaveTimeoutRef.current);
    autoSaveTimeoutRef.current = null;
    const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));

    selectedData.content = summeriseArticleData.content;
    selectedData.focus_card = summeriseArticleData.focus_card;
    if (selectedData.status === 'Draft' && selectedData.isEditPage) {
      autoSaveTimeoutRef.current = setTimeout(() => {
        handlePublish(true, selectedData.status, true);
      }, 5000);
    }
  };

  useEffect(() => {
    if (callAutoSaveData) {
      dispatch({ type: 'SET_CALL_AUTO_SAVE', value: false });
      handleAutoSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAutoSaveData]);

  const showDuration = () => {
    videoRef.current.pauseVideo();
    const duration = videoRef.current.getDuration();
    const seconds = parseInt(duration, 10);
    dispatch({ type: 'VIDEO_DURATION', formattedTime: seconds });
  };

  const onChangeActiveSegment = ({ type, value }) => {
    const data = JSON.parse(JSON.stringify(activeSegment));
    if (type === 'categories') {
      dispatch({ type: 'SET_DATA', value, fieldType: type });
    } else if (type === 'tag') {
      dispatch({ type: 'SET_DATA', value, fieldType: type });
    } else if (type === 'dtitle') {
      dispatch({ type: 'SET_DATA', value, fieldType: 'title' });
    } else if (type === 'digest') {
      dispatch({ type: 'SET_DATA', value, fieldType: 'digest' });
    } else if (type === 'title' || type === 'description') {
      data[type] = value;
    } else if (type === 'video_summary_tag') {
      const summary = videoSummary.filter((val) => val.key === value);
      if (!summary.length) {
        data.watch_label = null;
      } else {
        data.watch_label = value;
      }
    } else if (type === 'timestamp_start_ms') {
      const start = data.timestamp_start || '00:00:00:00';
      const time = start.split(':');
      time[3] = value > 99 || value < 0 ? time[3] : value;
      if (!time[3]) time[3] = '0';
      time[3] = time[3].length === 2 ? time[3] : parseInt(time[3], 10);
      // dispatch({ type: 'SET_DATA', value: moment({hour: time[0], minute: time[1], seconds: time[2], milliseconds: time[3]}), fieldType: 'timestamp_start' });
      data.timestamp_start = time.join(':');
      dispatch({
        type: 'SET_ACTIVE_SEGMENT',
        value: { ...data, index: data.id || fetchHighestSegmentIndex(segmentList) + 1 },
      });
    } else if (type === 'timestamp_end_ms' && value) {
      const end = data.timestamp_end || '00:00:00:00';
      const time = end.split(':');
      time[3] = value > 99 || value < 0 ? time[3] : value;
      time[3] = time[3].length === 2 ? time[3] : parseInt(time[3], 10);
      // dispatch({ type: 'SET_DATA', value: time.join(':'), fieldType: 'timestamp_end' });
      data.timestamp_end = time.join(':');
      dispatch({
        type: 'SET_ACTIVE_SEGMENT',
        value: { ...data, index: data.id || fetchHighestSegmentIndex(segmentList) + 1 },
      });
    } else {
      data[type] = value ? value.format('HH:mm:ss:SS') : null;
      if (type === 'timestamp_start' && value !== null) {
        const startTimeMoment = moment(
          `${moment().format('DD:MM:YY')} ${value.format('HH:mm:ss:SS')}`,
          'DD:MM:YY HH:mm:ss:SS',
        );
        const endTimeMoment = moment(
          `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_end}`,
          'DD:MM:YY HH:mm:ss:SS',
        );
        if (startTimeMoment.isAfter(endTimeMoment)) {
          dispatch({ type: 'SET_DATA', value, fieldType: 'timestamp_end' });
          // data.timestamp_end = null;
        }
      }
    }
    dispatch({
      type: 'SET_ACTIVE_SEGMENT',
      value: { ...data, index: data.id || fetchHighestSegmentIndex(segmentList) + 1 },
    });
  };

  const range = (start, end) => {
    const result = [];
    // eslint-disable-next-line no-plusplus
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledHours = () => {
    const hours = range(0, 60);
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    hours.splice(0, hour + 1);
    return hours;
  };

  const disabledMinutes = (h) => {
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
    if (h === hour) return range(min + 1, 60);
    if (min > 0) return range(0);
    return [];
  };

  const disabledSeconds = (h, m) => {
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
    const sec = Math.floor(moment.duration(formattedTime, 'seconds').seconds());
    if (h === hour && m === min) return range(sec, 60);
    if (sec > 0) return range(0);
    return [];
  };

  const disabledEndHours = () => {
    const hourStart = Math.floor(
      moment.duration(activeSegment.timestamp_start, 'seconds').asHours(),
    );
    const hours = range(0, 60);
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    return hours.filter((hr) => hr < hourStart || hr > hour);
  };

  const disabledEndMinutes = (h) => {
    if (activeSegment.timestamp_start !== null) {
      const mins = range(0, 60);
      const hourStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').asHours(),
      );
      const minStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').minutes(),
      );
      const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
      const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
      if (h === hour && hourStart !== hour) return range(min + 1, 60);
      if (h === hour && hourStart === hour)
        return mins.filter((time) => time < minStart || time > min);
      if (min > 0) return range(0);
      return [];
    }
  };

  const disabledEndSeconds = (h, m) => {
    if (activeSegment.timestamp_start !== null) {
      const secs = range(0, 60);
      const hourStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').asHours(),
      );
      const minStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').minutes(),
      );
      const secStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').seconds(),
      );
      const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
      const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
      const sec = Math.floor(moment.duration(formattedTime, 'seconds').seconds());

      if (h === hour && hourStart !== hour) {
        if (m === min && minStart !== min) return range(sec, 60);
        return range(0);
      }
      if (h === hour && hourStart === hour) {
        if (m === min && minStart !== min) return range(secStart, 60);
        if (m === min && minStart === min)
          return secs.filter((time) => time <= secStart || time >= sec);
        if (m !== min && minStart === m) return range(0, secStart);
        return range(0);
      }
      return [];
    }
  };

  const togglePreview = () => {
    dispatch({ type: 'SET_PREVIEW' });
  };

  const toggleSubtitle = () => {
    dispatch({ type: 'SET_SUBTITLE' });
  };

  /*const onChangeCropper = useCallback(
    (value, width, height) => {
      // console.log('PX videoCropper', value);
      const videoCropper = {
        topLeft: {
          x_coord_: calculateHeightWidth(width, Number(value.topLeft.x_coord_)),
          y_coord: calculateHeightWidth(height, value.topLeft.y_coord),
        },
        bottomRight: {
          x_coord_: calculateHeightWidth(width, value.bottomRight.x_coord_),
          y_coord: calculateHeightWidth(height, value.bottomRight.y_coord),
        },
      };
      dispatch({
        type: 'SET_ACTIVE_SEGMENT',
        value: { ...activeSegment, ...videoCropper },
      });
    },
    [activeSegment],
  );*/
  const [scrolling, setScrolling] = useState(false);
  const contain = document.getElementById('scrollabel');
  const scrollableBar = contain?.querySelector('#scrollableBar');

  const Scrollhandle = useCallback(() => {
    if (contain?.scrollTop >= scrollableBar?.offsetTop) {
      setScrolling(true);
    } else if (contain?.scrollTop <= scrollableBar?.offsetTop) {
      setScrolling(false);
    } else {
      setScrolling(false);
    }
  }, [contain?.scrollTop]);

  useEffect(() => {
    Scrollhandle();
    if (contain) {
      contain.addEventListener('scroll', Scrollhandle);
    }
    return () => {
      if (contain) {
        contain.removeEventListener('scroll', Scrollhandle);
      }
    };
  }, [Scrollhandle]);

  const video = urlString && urlString.match(validVideoUrl);
  let gridWidth;
  if (isMobile) gridWidth = 16;
  else if (!showFullLeft && !video) gridWidth = 13;
  else gridWidth = 10;
  return (
    <Layout
      isDashboard={!(video && editSummeriseData.id)}
      location={location}
      disabledUrl={false}
      urlString={urlString}
      path={`${location.pathname || '/digest-create'}`}
      errors={summeriseArticleErrors.url || ''}
    >
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
      <Content>
        <Grid padded="vertically" columns={2}>
          {!isMobile && (
            <Grid.Column
              width={
                summeriseArticleErrors.url || !originalArticleData
                  ? 3
                  : showFullLeft || video
                    ? 6
                    : 3
              }
            >
              {!loadingDigestPage ? (
                <>
                  {!summeriseArticleErrors.url && !video && (
                    <OriginalPage
                      data={originalArticleData}
                      handleCopyText={handleCopyText}
                      extractError={extractError}
                      digestId={digestId}
                      showFullLeft={showFullLeft}
                      setShowFullLeft={setShowFullLeft}
                      aspectRatio={aspectRatio}
                      setAspectRatio={(isRatio) => setAspectRatio(isRatio)}
                    />
                  )}
                  {video && (
                    <VideoColumn
                      cropCoord={cropCoord}
                      showDuration={showDuration}
                      showFullLeft={showFullLeft}
                      mobilePreviewData={mobilePreviewData}
                      setShowFullLeft={setShowFullLeft}
                      convertSecondsToTimeFormat={convertSecondsToTimeFormat}
                      urlString={urlString}
                      initialStatus={originalArticleData.originalStatus}
                      slug={originalArticleData.slug}
                      startTime={activeSegment.timestamp_start}
                      onSetActiveSegment={onChangeActiveSegment}
                      videoRef={videoRef}
                      segmentList={segmentList}
                      setVideoPublishLoading={loadingPublish}
                      disabledHours={disabledHours}
                      disabledMinutes={disabledMinutes}
                      disabledSeconds={disabledSeconds}
                      isMarkAsStart={isMarkAsStart}
                      setIsMarkAsStart={setIsMarkAsStart}
                      isReset={isReset}
                      setIsReset={setIsReset}
                      togglePreview={togglePreview}
                      coOrdinate={coOrdinate}
                      setCoOrdinate={setCoOrdinate}
                      toggleSubtitle={toggleSubtitle}
                      previewMode={previewMode}
                      subtitleMode={subtitleMode}
                      videoTranscriptData={videoTranscriptData}
                      activeTranscript={activeTranscript}
                      activeSegment={activeSegment}
                      onChangeTranscript={(value) => {
                        if (value) {
                          dispatch({ type: 'SET_ACTIVE_TRANSCRIPT', value });
                        }
                      }}
                      onChangeCropper={(value, width, height) => {
                        const maxWidth = document.getElementById('youtubePlayer').clientWidth;
                        const maxHeight = document.getElementById('youtubePlayer').clientHeight;

                        const videoCropper = {
                          topLeft: {
                            x_coord_: calculateHeightWidth(
                              maxWidth,
                              Number(value.topLeft.x_coord_),
                            ),
                            y_coord: calculateHeightWidth(maxHeight, value.topLeft.y_coord),
                          },
                          bottomRight: {
                            x_coord_: calculateHeightWidth(maxWidth, value.bottomRight.x_coord_),
                            y_coord: calculateHeightWidth(maxHeight, value.bottomRight.y_coord),
                          },
                        };
                        dispatch({
                          type: 'SET_ACTIVE_SEGMENT',
                          value: { ...activeSegment, ...videoCropper },
                        });
                      }}
                    />
                  )}
                </>
              ) : (
                <LoaderWrapper>
                  <Loader active />
                </LoaderWrapper>
              )}
            </Grid.Column>
          )}
          <Grid.Column id="scrollabel" width={gridWidth} style={{ borderLeft: 'none' }}>
            <SummerisePageWrapper>
              <SummerisePage
                dispatch={dispatch}
                handleEditorChange={handleEditorChange}
                editorRef={editorRef}
                key="summerisePage"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...state}
                scrollabel={scrolling}
                setEditSlug={setEditSlug}
                scoreList={scoreList}
                scoreRelevance={scoreRelevance}
                videoDigest={video}
                segmentList={segmentList}
                mobilePreviewData={mobilePreviewData}
                fetchSuggestions={fetchSuggestions}
                fetchTagSuggestions={fetchTagSuggestions}
                data={summeriseArticleData}
                activeSegment={activeSegment}
                activeSegmentIndex={activeSegmentIndex}
                errors={summeriseArticleErrors}
                onChange={handleChangeSummary}
                videoRef={videoRef}
                isMarkAsStart={isMarkAsStart}
                setIsMarkAsStart={setIsMarkAsStart}
                digestId={digestId}
                FAQData={FAQData}
                faqId={faqId}
                faqStatus={faqStatus}
                disabledEndHours={disabledEndHours}
                disabledEndMinutes={disabledEndMinutes}
                disabledEndSeconds={disabledEndSeconds}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                disabledSeconds={disabledSeconds}
                previewMode={previewMode}
                segmentError={segmentError}
                addChoices={addChoices}
                removeChoice={removeChoice}
                hanldeAddTags={handleAddTags}
                setCoOrdinate={setCoOrdinate}
                isReset={isReset}
                setIsReset={setIsReset}
                handleAddTagsGroups={handleAddTagsGroups}
                onChangeCategoriesOption={onChangeCategoriesOption}
                validateSlug={validateSlug}
                autoSummarize={autoSummarize}
                checkPlagiarism={checkPlagiarism}
                plagiarised={plagiarised}
                plagiarisedTitle={plagiarisedTitle}
                handleSegmentPreview={(data, index) => {
                  dispatch({ type: 'SET_SEGMENTS_PREVIEW', value: data });
                }}
                aspectRatio={aspectRatio}
                setAspectRatio={(isRatio) => setAspectRatio(isRatio)}
                onClickSegment={(value, rsestSegment) => {
                  if (!value.id) {
                    dispatch({
                      type: 'SET_ACTIVE_SEGMENT',
                      value: {
                        ...value,
                      },
                    });
                  } else {
                    //frame sifting
                    const { timeCoordinate } = value;

                    const perCoOrdinateData = {};
                    const maxWidth = document.getElementById('youtubePlayer').clientWidth;
                    const maxHeight = document.getElementById('youtubePlayer').clientHeight;
                    // eslint-disable-next-line array-callback-return
                    Object.keys(timeCoordinate).map((key) => {
                      const { topLeft, bottomRight } = timeCoordinate[key];
                      perCoOrdinateData[key] = {
                        topLeft: {
                          x_coord_: percentageToPx(maxWidth, topLeft.x_coord_),
                          y_coord: percentageToPx(maxHeight, topLeft.y_coord),
                        },
                        bottomRight: {
                          x_coord_: percentageToPx(maxWidth, bottomRight.x_coord_),
                          y_coord: percentageToPx(maxHeight, bottomRight.y_coord),
                        },
                      };
                    });
                    setIsMarkAsStart(false);
                    setCoOrdinate(perCoOrdinateData);
                    if (value.topLeft && value.bottomRight) {
                      setCropCoord({
                        topLeft: value.topLeft,
                        bottomRight: value.bottomRight,
                      });
                    }
                    //end
                    if (videoRef && videoRef.current && !rsestSegment) {
                      // const a = value.timestamp_start.split(':'); // split it at the colons
                      const seconds = convertTimeToSeconds(value.timestamp_start);
                      if (typeof videoRef.current.seekTo === 'function') {
                        videoRef.current.seekTo(seconds);
                        videoRef.current.playVideo();
                      }
                    }

                    if (rsestSegment) {
                      setCropCoord({ ...DEFAULT_CROP_COORD });
                    }

                    const watchLabel = videoSummary.filter(
                      (summary) => summary.text === value.watch_label,
                    );
                    let label = '';
                    if (watchLabel.length) label = watchLabel[0].key;
                    dispatch({
                      type: 'SET_ACTIVE_SEGMENT',
                      value: {
                        ...value,
                        watch_label: label,
                        timeCoordinate: perCoOrdinateData,
                      },
                      index: value.id || fetchHighestSegmentIndex(segmentList) + 1,
                    });
                  }
                }}
                removeSegment={(value) => {
                  const index = segmentList.findIndex(
                    (item) => item.segment_id === value.segment_id,
                  );
                  const data = JSON.parse(JSON.stringify(segmentList));
                  data.splice(index, 1);
                  if (activeSegment.id === value.id) {
                    dispatch({
                      type: 'SET_ACTIVE_SEGMENT',
                      value: {
                        title: null,
                        timestamp_start: convertSecondsToTimeFormat(0),
                        timestamp_end: convertSecondsToTimeFormat(1),
                        description: '',
                      },
                      index: fetchHighestSegmentIndex(segmentList) + 1,
                    });
                  }
                  dispatch({ type: 'SET_SEGMENT_LIST', value: data });
                }}
                onChangeActiveSegment={onChangeActiveSegment}
                videoSummary={videoSummary}
                onSegmentSave={() => {
                  setCropCoord({ ...DEFAULT_CROP_COORD });
                  setIsReset(false);
                  if (activeSegment) {
                    // eslint-disable-next-line camelcase
                    const { title, watch_label } = activeSegment;
                    const errors = {};
                    if (!title || !title.trim()) {
                      errors.segment_title = 'This field is mandatory.';
                    }
                    const startTimeMoment = moment(
                      `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_start}`,
                      'DD:MM:YY HH:mm:ss:SSS',
                    );
                    const endTimeMoment = moment(
                      `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_end}`,
                      'DD:MM:YY HH:mm:ss:SSS',
                    );
                    const perCoOrdinateData = {};
                    const maxWidth = document.getElementById('youtubePlayer').clientWidth;
                    const maxHeight = document.getElementById('youtubePlayer').clientHeight;
                    // eslint-disable-next-line array-callback-return
                    Object.keys(coOrdinate).map((key) => {
                      const { topLeft, bottomRight } = coOrdinate[key];
                      perCoOrdinateData[key] = {
                        topLeft: {
                          x_coord_: calculateHeightWidth(maxWidth, topLeft.x_coord_),
                          y_coord: calculateHeightWidth(maxHeight, topLeft.y_coord),
                        },
                        bottomRight: {
                          x_coord_: calculateHeightWidth(maxWidth, bottomRight.x_coord_),
                          y_coord: calculateHeightWidth(maxHeight, bottomRight.y_coord),
                        },
                      };
                    });
                    if (endTimeMoment.isAfter(startTimeMoment)) {
                      let data = JSON.parse(JSON.stringify(segmentList));
                      const activeData = JSON.parse(
                        JSON.stringify({
                          ...activeSegment,
                          title: activeSegment.title.trim(),
                          description: activeSegment.description
                            ? activeSegment.description.trim()
                            : '',
                          is_for_preview: true,
                          timeCoordinate: perCoOrdinateData,
                        }),
                      );
                      // eslint-disable-next-line camelcase
                      const summary = videoSummary.filter((value) => value.key === watch_label);
                      if (activeData.id) {
                        data = data.map((item) => {
                          if (item.id === activeData.id) {
                            if (!summary.length) activeData.watch_label = null;
                            else activeData.watch_label = summary[0].text;
                            return activeData;
                          }
                          return item;
                        });
                      } else {
                        activeData.id = fetchHighestSegmentIndex(segmentList) + 1;
                        data.push(activeData);
                        if (summary.length) data[data.length - 1].watch_label = summary[0].text;
                      }
                      data.sort((a, b) => {
                        const dateA = moment(
                          `${moment().format('DD:MM:YY')} ${a.timestamp_start}`,
                          'DD:MM:YY HH:mm:ss:SSS',
                        );
                        const dateB = moment(
                          `${moment().format('DD:MM:YY')} ${b.timestamp_start}`,
                          'DD:MM:YY HH:mm:ss:SSS',
                        );
                        return dateA - dateB; // sort by date ascending
                      });
                      data.forEach((val, index) => {
                        val.segment_id = index + 1;
                      });
                      if (!Object.keys(errors).length)
                        dispatch({ type: 'SET_SEGMENT_LIST', value: data });
                    } else {
                      errors.timestamp_start = 'Start time must be less than end time.';
                    }
                    dispatch({ type: 'SET_SEGMENT_ERRORS', value: errors });
                    if (Object.keys(summeriseArticleErrors).length && !Object.keys(errors).length) {
                      const data = trimObjectValues(
                        JSON.parse(JSON.stringify(summeriseArticleData)),
                        {
                          removeBlanks: true,
                        },
                      );
                      const tempError = checkSummariseErrors({
                        data,
                        segment: activeSegment,
                        transcriptError,
                      });
                      dispatch({ type: 'SET_SUMMERISE_ARTICLE_ERRORS', value: tempError });
                    }
                  }
                }}
                updateActiveSegment={(value) => {
                  dispatch({
                    type: 'SET_ACTIVE_SEGMENT',
                    value: { ...value },
                  });
                  setCoOrdinate({});
                  setCropCoord({ ...DEFAULT_CROP_COORD });
                }}
                handleChangeStatus={(value) => {
                  const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
                  selectedData.content = summeriseArticleData.content;
                  selectedData.focus_card = summeriseArticleData.focus_card;
                  selectedData.status = value;
                  dispatch({ type: 'SET_SUMMERISE_ARTICLE_DATA', value: selectedData });
                  // setSummariseFormState(selectedData);
                }}
                handlePublish={handlePublish}
              />
            </SummerisePageWrapper>
          </Grid.Column>
        </Grid>
        {confirmCategoryAdd && (
          <Modal size="tiny" open={confirmCategoryAdd}>
            <Modal.Content>
              {localStorage.getItem('privilege') !== 'SUPER_USER_PERM'
                ? 'You do not have permission to add new category.'
                : 'This will create a new category. New category should not be created until absolutely necessary. Are you sure you want to update?'}
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                // disabled={deleteDigestsLoading}
                onClick={() => {
                  dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: '' });
                }}
              >
                {localStorage.getItem('privilege') !== 'SUPER_USER_PERM' ? 'Ok' : 'Cancel'}
              </Button>
              {localStorage.getItem('privilege') === 'SUPER_USER_PERM' && (
                <Button
                  positive
                  content="Add"
                  // disabled={deleteDigestsLoading}
                  onClick={() => {
                    dispatch({ type: 'ADD_CATEGORY' });
                  }}
                />
              )}
            </Modal.Actions>
          </Modal>
        )}
        {addCategory && (
          <Modal size="tiny" open={confirmCategoryAdd}>
            <Modal.Header>Add a category icon</Modal.Header>
            <Modal.Content>
              <ImageWrapper>
                <ImageContainer imageUrl={categoryImage}>
                  {categoryImage && <Image src={categoryImage} />}
                  <CopyIconImage
                    onClick={() =>
                      dispatch({
                        type: 'UPLOAD_IMAGE_STATUS',
                        status: true,
                        addNewUpload: true,
                      })
                    }
                  >
                    <Icon name="plus" size="large" />
                  </CopyIconImage>
                  {uploadImage && (
                    <UploadImageComponent
                      onSave={(url) => {
                        dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: false });
                        dispatch({ type: 'SET_IMAGE', value: url });
                      }}
                      categoryAdd
                      open={uploadImage}
                      onClose={() => {
                        dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: false });
                      }}
                    />
                  )}
                </ImageContainer>
              </ImageWrapper>
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                onClick={() => {
                  dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: '' });
                  dispatch({ type: 'SET_IMAGE', value: '' });
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                content="Add"
                disabled={!categoryImage}
                onClick={() => {
                  dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: '' });
                  saveCategory({ title: confirmCategoryAdd, icon: categoryImage })
                    .then((response) => {
                      ToastsStore.success('Category added');
                      const selectedData = JSON.parse(JSON.stringify(summeriseArticleData));
                      selectedData.content = summeriseArticleData.content;
                      selectedData.focus_card = summeriseArticleData.focus_card;
                      selectedData.categories.push(response.data);
                      setSummariseFormState(selectedData);
                      fetchSuggestions('', selectedData.categories);
                      dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: '' });
                    })
                    .catch((error) => {
                      ToastsStore.error(error.response.data || 'Unable to create new category.');
                    });
                }}
              />
            </Modal.Actions>
          </Modal>
        )}
      </Content>
    </Layout>
  );
};

Dashboard.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export default Dashboard;
