import React from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import Table from 'rc-table';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Pagination from '../Pagination';
import 'rc-table/assets/index.css';

import DropDown from './DropDown';

const SortButtonWrapper = styled.span`
  padding: 0 5px;
`;

const HeaderTitle = styled.div`
  white-space: normal;
`;

const CustomHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => textAlign || 'flex-start'};
  cursor: pointer;
  text-align: ${({ textAlign }) => textAlign};

  .dropdown-icon {
    ${({ selectedFilter }) =>
    selectedFilter
      ? `
      color: #0152cc;
    `
      : ``};
  }
`;

const HeaderWrapper = styled.thead`
  background-color: #fff;
  border-bottom: 2px solid #f3f3f3;
`;

const HeaderCell = styled.th`
  padding: 14px 8px !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold !important;
  font-size: 14px !important;
  text-align: left;
  background-color: #fff !important;
  border: 1px solid #d8d8d8 !important;
`;

const BodyCell = styled.td`
  font-size: 12px;
  color: #52575a;
  word-wrap: normal !important;
  word-break: unset !important;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: unset !important;
  border: 1px solid #d8d8d8 !important;
`;

const TableRow = styled.tr`
  cursor: pointer;
`;

const TableWrapper = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 4px;
  overflow: unset;

  .action-column {
    padding: 8px 8px;
  }

  .action-wrapper {
    display: none;
  }

  .rc-table-row-hover,
  .rc-table-row:hover {
    .action-wrapper {
      display: block;
    }
    .rc-table-fixed-columns-in-body {
      visibility: unset;
      > tr {
        border-right: none !important;
      }
      > .action-wrapper {
        display: none;
      }
      > span {
        display: none;
      }
    }
  }

  .rc-table-cell-fix-right-first,
  .rc-table-cell-fix-right-last {
    box-shadow: -1px 0 0 #d8d8d8;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse !important;
  }

  tbody {
    vertical-align: ${({ vAlign }) => vAlign || 'middle'};
  }

  .table-even-row {
    background-color: #f7f9fb;
  }

  .rc-table-fixed .col {
    background-color: #000000;
  }

  .rc-table-container {
    overflow: scroll;
  }
  .action-wrapper-main {
    right: 0 !important;
  }

  .rc-table-body {
    overflow: unset !important;
    border: 1px solid #d8d8d8;
    outline: none;

    &:after {
      border: 1px solid #d8d8d8;
    }
  }

  .rc-table-ping-left .rc-table-cell-fix-left-last:after {
    box-shadow: inset 10px 0 8px -8px #d8d8d8;
  }
  .rc-table-header {
    border: 1px solid #d8d8d8;
  }
  .rc-table-placeholder {
    border-bottom: none !important;
    font-size: 16px;
    z-index: -1;
  }

  .rc-table-scroll,
  .rc-table {
    min-height: 250px;
  }

  td {
    border-bottom: none !important;
  }

  .rc-table-fixed-columns-in-body {
    visibility: unset;

    > div {
      display: none;
    }

    > span {
      display: none;
    }
  }

  .rc-table-fixed {
    colgroup {
      col {
        &:first-child {
          min-width: 70px !important;
          width: 70px !important;
        }
      }
    }
  }

  .rc-table-fixed-left {
    td,
    th {
      border-right: none !important;
    }
  }

  .rc-table-fixed-right {
    td,
    th {
      border-right: none !important;
    }
  }
  .rc-table th,
  .rc-table td {
    white-space: break-spaces;
  }
  // .rc-table tr {
  //   min-height: 120px;
  // }

   .rc-table-row-level-1 td {  
     color: #000;
     font-weight: 700;
   }
}
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;

  .rc-pagination-prev,
  .rc-pagination-next,
  rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const SortIcon = styled(Icon)`
  opacity: 0.8;
`;

const DropDownText = styled.span`
  font-size: 16px;
  color: ${({ activeFiler }) => (activeFiler ? '#0152cc' : 'rgba(0,0,0,0.6)')};
`;

const DropDownOption = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  color: ${({ selected }) => (selected ? '#f32826' : '#000')};
  cursor: pointer;
`;

export const createDropDown = (
  options,
  onSelect,
  selectedOption,
  multiple,
  selectedValue,
  setSelected,
) => {
  let newValue = [];
  if (multiple) newValue = [...selectedValue];
  return (
    <>
      {options &&
        options.map((option) => (
          <DropDownOption
            key={option.value}
            selected={
              multiple
                ? selectedValue && selectedValue.includes(option.value)
                : selectedOption === option.value
            }
            onClick={() => {
              if (multiple) {
                if (!selectedValue.includes(option.value)) {
                  newValue.push(option.value);
                  setSelected({ value: newValue, option });
                } else {
                  newValue = newValue.filter((val) => val !== option.value);
                  setSelected({ value: newValue, option });
                }
              } else onSelect(option.value !== selectedOption ? option.value : '', option);
            }}
            data-test={`select-${option.key || option.value}`}
          >
            {option.text}
          </DropDownOption>
        ))}
    </>
  );
};
const renderSortColumnHeader = ({
  columns,
  toggleSortOrder,
  sortOrder,
  sortColumn,
  selectedValue,
  setSelected,
  open,
  setOpen,
  setInitial,
  initial,
  selectedOptions,
}) =>
  columns &&
  columns.flatMap((column) => {
    const onSelect = () => {
      setOpen(false);
      column.onFilterSelect(selectedValue, selectedOptions);
    };
    const onHeaderCell = () => {
      let colProps = {};

      if (column.onHeaderCell) {
        colProps = {
          ...column.onHeaderCell,
        };
      }
      const onHeaderCellClick = colProps.onClick;
      colProps.onClick = (...args) => {
        if (column.sorter) {
          toggleSortOrder(column);
          if (onHeaderCellClick) onHeaderCellClick(...args);
        }
      };

      return colProps;
    };
    const ascendIcon = (sortColumn === column.dataIndex ||
      sortColumn === column.dataIndex.split('.').reverse()[0]) &&
      sortOrder === 'ascend' && <SortIcon name="sortAsc" size={18} color="#4a4a4a" />;

    const descendIcon = (sortColumn === column.dataIndex ||
      sortColumn === column.dataIndex.split('.').reverse()[0]) &&
      sortOrder === 'descend' && <SortIcon name="sortDes" size={18} color="#4a4a4a" />;
    const sortIcon = !ascendIcon && !descendIcon && <Icon name="sort" size={18} color="#4a4a4a" />;
    const sortButton = (
      <SortButtonWrapper>
        {sortIcon}
        {descendIcon}
        {ascendIcon}
      </SortButtonWrapper>
    );

    let customTitle = column.title;
    if (column.sorter || column.filter) {
      if (column.multiple && initial)
        setSelected({ value: [...column.selectedOption], openValue: true, initialize: initial });
      let columnSelected = false;
      if (column.multiple && column.selectedOption.length) columnSelected = true;
      else if (!column.multiple && column.selectedOption) columnSelected = true;

      customTitle = (
        <CustomHeaderCell
          selectedFilter={columnSelected}
          textAlign={column.align}
          data-test={column.dataTest}
        >
          {column.filter ? (
            <DropDown
              dropDownIconColor={columnSelected ? 'blue' : 'black'}
              text={<DropDownText activeFiler={columnSelected}>{column.title}</DropDownText>}
              bottomPosition={12}
              position={column.filterAlign || 'bottomRight'}
              multiple={column.multiple}
              open={open}
              onSelect={onSelect}
            >
              {createDropDown(
                column.filterOptions,
                column.onFilterSelect,
                column.selectedOption,
                column.multiple,
                selectedValue,
                setSelected,
                // setSelected,
                // column.selectedValue,
                // column.setSelectedValue,
              )}
            </DropDown>
          ) : (
            <HeaderTitle>{column.title}</HeaderTitle>
          )}
          {column.sorter && sortButton}
        </CustomHeaderCell>
      );
    }

    // eslint-disable-next-line no-param-reassign
    column = {
      ...column,
      title: customTitle,
      onHeaderCell,
    };
    return column;
  });

const tableComponents = {
  header: {
    wrapper: HeaderWrapper,
    cell: HeaderCell,
  },
  body: {
    cell: BodyCell,
    row: TableRow,
  },
};

const TableView = ({
  pageSizeOptions,
  pageSizeChange,
  onPageSizeChange,
  tableColumns,
  loading,
  data,
  totalItems,
  activePage,
  onPageChange,
  toggleSortOrder,
  sortOrder,
  sortBy,
  onRow,
  pagination,
  scroll,
  pageSize,
  vAlign,
  expandIconColumnIndex = 0,
  indentSize,
  expandIcon,
  expandRowByClick,
  rowClassName = () => { }
}) => {
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [initial, setInitial] = React.useState(true);
  const setSelected = ({ value, openValue, initialize, option }) => {
    setSelectedValue(value);
    const tempSelected = selectedOptions.slice();
    if (tempSelected[0] === undefined) tempSelected.splice(0, 1);
    let index = -1;
    if (selectedOptions.length) index = selectedOptions.findIndex((x) => x && x.value === value);
    if (index === -1) tempSelected.push(option);
    else tempSelected.slice(index, 1);
    setSelectedOptions(tempSelected);
    if (!openValue) setOpen(true);
    if (initialize) setInitial(false);
  };
  const columns = renderSortColumnHeader({
    columns: tableColumns,
    toggleSortOrder,
    sortOrder,
    sortColumn: sortBy,
    selectedValue,
    setSelected,
    initial,
    open,
    setOpen,
    selectedOptions,
    className: 'tblColumn',
  });

  return (
    <>
      {loading && <Loader active={loading} />}
      {!loading && (
        <>
          <TableWrapper vAlign={vAlign}>
            <Table
              key={`${data?.length}_${Math.ceil(Math.random() * 100)}`} //! Force rerender
              rowKey="id"
              columns={columns}
              data={data}
              scroll={scroll || { x: 1000, y: false }}
              components={tableComponents}
              onRow={onRow}
              expandIconColumnIndex={expandIconColumnIndex}
              expandRowByClick={expandRowByClick}
              expandIcon={expandIcon}
              indentSize={indentSize}
              rowClassName={rowClassName}
              className="tbl"
            // useFixedHeader={false}
            />
          </TableWrapper>
          {pagination && totalItems > (pageSize || 20) && (
            <PaginationWrapper>
              <Pagination
                pageSizeChange={pageSizeChange}
                pageSizeOptions={pageSizeOptions}
                onPageSizeChange={onPageSizeChange}
                activePage={activePage}
                totalItems={totalItems || (data && data.length) || 0}
                totalPages={Math.round(totalItems / (pageSize || 20))}
                onChangePage={(current) => onPageChange(current)}
                pageSize={pageSize || 20}
              />
            </PaginationWrapper>
          )}
        </>
      )}
    </>
  );
};

TableView.propTypes = {
  tableColumns: PropTypes.array,
  loading: PropTypes.bool,
  data: PropTypes.array,
  totalItems: PropTypes.number,
  activePage: PropTypes.number,
  onPageChange: PropTypes.func,
  toggleSortOrder: PropTypes.func,
  sortOrder: PropTypes.string,
  sortBy: PropTypes.string,
  onRow: PropTypes.func,
  pagination: PropTypes.bool,
  scroll: PropTypes.number,
  pageSize: PropTypes.number,
  vAlign: PropTypes.string,
};

export default TableView;
